import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { getPostObj } from "../utils/utils"

import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import Body from "./doctor/Body"
import SEO from "../components/seo"

import TopSection from "./doctor/TopSection"

const SingleDoctor = ({ pageContext, location, data }) => {
  const postObj = getPostObj(
    pageContext,
    data.allDoctorsJson.nodes[0],
    data.allSpanishDoctorsJson.nodes[0]
  )
  const { post, language } = postObj

  const socialData = getBestReviews()

  function getBestReviews() {
    // Check if fields exist and at least one location has one review
    // graphql filters out ratings less than 4.0 and those with 0 reviews
    if (
      data.allLocationMatches?.nodes?.length > 0 &&
      post.gmbIds?.length > 0 &&
      hasAtLeastOneReview(data.allLocationMatches.nodes)
    ) {
      // Check for doctor review location with most reviews, ties broken by whichever has highest rating
      let locationWithMostReviews = data.allLocationMatches.nodes[0]
      for (let i = 0; i < data.allLocationMatches.nodes.length; i++) {
        const locationMatch = data.allLocationMatches.nodes[i]
        if (
          locationMatch.data.totalReviews >
          locationWithMostReviews.data.totalReviews
        ) {
          locationWithMostReviews = locationMatch
        } else if (
          locationMatch.data.totalReviews ===
          locationWithMostReviews.data.totalReviews
        ) {
          if (locationMatch.data.rating > locationWithMostReviews.data.rating) {
            locationWithMostReviews = locationMatch
          }
        }
      }
      return locationWithMostReviews
    }
    if (data.matchingLocations.nodes.length > 0) {
      return getLocationWithMostReviews()
    }
  }

  function hasAtLeastOneReview(socialData) {
    for (let i = 0; i < socialData.length; i++) {
      if (socialData[i].data.totalReviews > 0) {
        return true
      }
    }
    return false
  }

  function getLocationWithMostReviews() {
    // matchingLocations is the gmb IDs of all locations that the doctor is associated with
    // Loop through all social data and find ones that platformId matches the matchingLocations' gmb IDs
    const associatedLocationsReviewData = []
    for (let i = 0; i < data.allSocials.nodes.length; i++) {
      for (let j = 0; j < data.matchingLocations.nodes.length; j++) {
        if (
          data.allSocials.nodes[i].data.platformId ===
          data.matchingLocations.nodes[j].locationData.gmbId
        ) {
          associatedLocationsReviewData.push(data.allSocials.nodes[i])
        }
      }
    }

    // Find Location with Most Reviews
    let locationWithMostReviews = associatedLocationsReviewData[0]
    for (let i = 0; i < associatedLocationsReviewData.length; i++) {
      if (
        associatedLocationsReviewData[i].data.totalReviews >
        locationWithMostReviews.data.totalReviews
      ) {
        locationWithMostReviews = associatedLocationsReviewData[i]
      }
    }

    return locationWithMostReviews
  }

  const backUrl = `/${data.backButton.nodes[0].title}`

  const ctaClasses = classNames("column", {
    "is-2": post.hasImages,
    "is-4": !post.hasImages
  })

  return (
    <Layout language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <div className="doctor-page joshua-tree-content">
        <TopSection
          language={language}
          post={post}
          backUrl={backUrl}
          doctor={socialData.data}
          // doctor={false}
        />
        <Body language={language} post={post} />

        {/* Learn More CTA Section */}
        <div className="body-section color-back doctor-cta">
          <div className="columns has-text-centered">
            <div className="column">
              <h3 style={{ marginBottom: "1.125rem" }}>
                {post.learnMore.heading}
              </h3>
            </div>
          </div>
          <div className="columns has-text-centered">
            <div className={ctaClasses} />
            <div className="column mobile-col">
              <p>{post.learnMore.blurb}</p>
            </div>
            <div className={ctaClasses} />
          </div>
          <div className="columns">
            <div className="column">
              <ButtonGroupMap isCentered buttons={post.learnMore.buttons} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query doctorPages(
    $title: String!
    $gmbIds: [String]
    $locations: [String]
    $locationReviews: String
  ) {
    backButton: allUniquePagesJson(filter: { template: { eq: "about-us" } }) {
      nodes {
        title
      }
    }
    allDoctorsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        gmbIds
        hasProfileImage
        hasBodyImages
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        educationResidency {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          textOrPhotoFirst
        }
        hobbiesInterests {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          textOrPhotoFirst
        }
        certifications {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          textOrPhotoFirst
        }
        learnMore {
          heading
          blurb
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
    allSpanishDoctorsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        gmbIds
        hasProfileImage
        hasBodyImages
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        educationResidency {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        hobbiesInterests {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        certifications {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        learnMore {
          heading
          blurb
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
    allLocationMatches: allSocialJson(
      filter: {
        data: {
          rating: { gte: 4 }
          platformId: { in: $gmbIds }
          totalReviews: { gt: 0 }
        }
      }
    ) {
      nodes {
        data {
          rating
          totalReviews
          url
        }
      }
    }
    review: allSocialJson(
      filter: { data: { platformId: { eq: $locationReviews } } }
    ) {
      nodes {
        data {
          rating
          totalReviews
          url
        }
      }
    }
    allSocials: allSocialJson {
      nodes {
        data {
          rating
          totalReviews
          url
          platformId
        }
      }
    }
    matchingLocations: allLocationsJson(
      filter: { pageData: { title: { in: $locations } } }
    ) {
      nodes {
        locationData {
          gmbId
        }
      }
    }
  }
`

SingleDoctor.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.shape({
    allDoctorsJson: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          heading: PropTypes.string,
          metaTitle: PropTypes.string,
          metaDescription: PropTypes.string,
          hasImages: PropTypes.bool
        })
      ).isRequired
    }).isRequired
  }).isRequired
}

export default SingleDoctor
