import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import SEO from "../components/seo"

import TopSection from "./about/TopSection"
import OurMission from "./about/OurMission"
import MeetOurDoctors from "./about/MeetOurDoctors"
import OurReviews from "./about/OurReviews"
import MeetOurTeam from "./about/MeetOurTeam"
import ReasonsWhy from "./about/ReasonsWhy"
import OurOffice from "./about/OurOffice"
import BannerSection from "./about/BannerSection"
import LearnMoreCTA from "./about/LearnMoreCTA"
import OverlappedImageText from "./about/OverlappedImageText"

import OurOfficeTour from "./about/OurOfficeTour"
import AboutVideo from "./about/AboutVideo"

const AboutUsPage = ({ pageContext, location, data }) => {
  const { language } = pageContext
  const post =
    language === "es"
      ? data.allSpanishUniquePagesJson.nodes[0]
      : data.allUniquePagesJson.nodes[0]

  const team = data.team.nodes[0].meetOurTeam.staffImages

  return (
    <Layout language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <div className="joshua-tree-content about-us">
        <TopSection post={post} language={language} />
        <OurMission post={post} language={language} sideColumnIs={4} />
        <MeetOurDoctors post={post} language={language} />
        <AboutVideo locations={2} post={post} language={language} colorBack />
        <OurReviews post={post} language={language} />
        <ReasonsWhy
          post={post}
          language={language}
          invert={language === "es"}
        />

        {post.textOverlappedImages.hasThisSection && (
          <OverlappedImageText items={post.textOverlappedImages.items} />
        )}

        <MeetOurTeam
          post={post}
          language={language}
          team={team}
          invert={language === "es"}
        />

        <OurOffice
          locations={2}
          post={post}
          language={language}
          colorBack={language != "es"}
        />

        {/* <OurOfficeTour
            locations={2}
            post={post}
            language={language}
            colorBack
          /> */}

        {post.bannerSection && post.bannerSection.hasThisSection && (
          <BannerSection post={post} />
        )}
        <LearnMoreCTA
          colorBack={language === "es"}
          post={post}
          language={language}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
        }
        ourOffice {
          imageId
          text
          imageIdMobile
          locationCopy
          hasThisSection
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            reason {
              blurb
              heading
              number
            }
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          useImageStrip
          videos {
            patients {
              caption
              imageId
              imageIdMobile
              youtube
            }
            referringProviders {
              caption
              imageId
              imageIdMobile
              youtube
            }
          }
          imageStrip {
            youtube
            images {
              imageId
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              imageIdHover
              url
            }
          }
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          text
        }
        metaTitle
        metaDescription
        title
        mainVideo {
          imageId
          imageIdMobile
          youtube
        }
        bannerSection {
          hasThisSection
          logoId
          text
        }
        textOverlappedImages {
          hasThisSection
          items {
            isReversed
            heading
            blurb
            imageId
            button {
              destination
              appearance
              buttonText
              href
            }
          }
        }
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
        aboutVideo {
          youtube
          imageId
          text
          hasThisSection
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
        }
        aboutVideo {
          youtube
          imageId
          text
          hasThisSection
        }
        ourOffice {
          imageId
          text
          imageIdMobile
          hasThisSection
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            reason {
              blurb
              heading
              number
            }
          }
        }
        textOverlappedImages {
          hasThisSection
          items {
            isReversed
            heading
            blurb
            imageId
            button {
              destination
              appearance
              buttonText
              href
            }
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              url
              imageIdHover
            }
          }
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          text
        }
        metaTitle
        metaDescription
        title
        mainVideo {
          imageId
          youtube
          imageIdMobile
        }
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
    team: allUniquePagesJson(filter: { template: { eq: "about-us" } }) {
      nodes {
        meetOurTeam {
          staffImages {
            staffMember {
              imageId
              imageIdHover
              staffName
              staffTitle
              spanishTitle
            }
          }
        }
      }
    }
  }
`

export default AboutUsPage
