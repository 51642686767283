import React from "react"
import classNames from "classnames"
import { Transformation } from "cloudinary-react"
import ImageMeta from "../../components/ImageMeta"

import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import MarkdownViewer from "@components/MarkdownViewer"
import Button from "@components/Button/Button"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

function OurOfficeTour(props) {
  if (!props.post.ourOffice.hasThisSection) return <></>

  const mainClasses = classNames("body-section our-office", {
    "color-back": props.colorBack
  })

  return (
    <section className={mainClasses}>
      {props.post.ourOffice.text && (
        <div className="columns">
          <div className="column is-2" />
          <div
            className="column mobile-col has-text-centered-tablet"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.ourOffice.text)
            )}
          />
          <div className="column is-2" />
        </div>
      )}

      <div className="columns has-text-centered office-tours">
        {props.post.ourOffice.tours.map(tour => {
          return (
            <>
              <div className="column is-2"></div>
              <div className="column">
                <MarkdownViewer markdown={tour.tour.text} />
                <div className="column tour-iframe">
                  <iframe
                    src={tour.tour.iframeSrc}
                    width="100%"
                    height="400px"
                    frameborder="0"
                    allowfullscreen="allowfullscreen"></iframe>
                </div>
                <Button
                  href={tour.tour.button.href}
                  buttonText={tour.tour.button.buttonText}
                  contained
                />
              </div>
            </>
          )
        })}
        <div className="column is-2"></div>
      </div>
    </section>
  )
}

export default OurOfficeTour
