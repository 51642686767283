import React from "react"
import { graphql, Link } from "gatsby"
import classnames from "classnames"
import MarkdownViewer from "@components/MarkdownViewer"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetStarted from "../components/CTA/GetStarted"

const CommunityQueryPage = props => {
  const { pageContext } = props
  const { location } = props
  const { data } = props
  let post
  let language

  if (pageContext && pageContext.language) {
    language = pageContext.language
    switch (language) {
      case "en":
        post = data.allUniquePagesJson.nodes[0]

        break
      case "es":
        post = data.allSpanishUniquePagesJson.nodes[0]

        break
      default:
        post = data.allUniquePagesJson.nodes[0]
    }
  } else {
    post = data.allUniquePagesJson.nodes[0]
  }

  return (
    <Layout language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <div className="joshua-tree-content community-query-page">
        {/* Top Section */}
        <div className="columns top-section white-back">
          <div className="column">
            <h1 style={{ textAlign: "center" }}>{post.heading}</h1>
            <div className="columns">
              <div className="column is-4" />
              <div className="column">
                <MarkdownViewer markdown={post.blurb} />
              </div>
              <div className="column is-4" />
            </div>
          </div>
        </div>

        <section className="body-section community">
          {/* <Grid pages={post.pages} /> */}
          <ThreeImageGrid pages={post.pages} gridSize={post.pages.length} />
        </section>

        <section
          className="body-section community-get-started color-back"
          style={{ paddingBottom: "60px" }}>
          <GetStarted
            sideColumnIs={3}
            headingSideColumnIs={2}
            centerHeading
            centerText
            heading={post.getStarted.heading}
            paragraph={post.getStarted.blurb}
            buttons={post.getStarted.buttons}
          />
        </section>
      </div>
    </Layout>
  )
}

function Grid(props) {
  // Split into rows of 2
  const chunk_size = 2
  const arr = props.pages
  const groups = arr
    .map((e, i) => (i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null))
    .filter(e => e)

  return groups.map((row, i) => (
    <GridRow key={`community-row-${i}`} items={row} />
  ))
}

function GridRow(props) {
  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const { page } = item
        const itemLen = props.items.length
        return (
          <>
            {i === 0 && <div className="column is-2" />}
            <div
              className={`column community-item ${
                itemLen === i + 1 ? "is-9" : "is-9"
              }`}>
              <Link className="hover-link" to={page.href}>
                <NuvoImage
                  useAR
                  cloudName="nuvolum"
                  publicId={page.img}
                  width="auto"
                  responsive
                  className="meet-our-doctor"
                />
              </Link>
              <div className="community-subtext">
                <Link className="heading-link hover-link" to={page.href}>
                  <h3>{page.heading}</h3>
                </Link>
                <Link
                  className="standard-button contained"
                  style={{ marginTop: 0 }}
                  to={page.href}>
                  Learn More
                </Link>
              </div>
            </div>
            <div className="column is-2" />
          </>
        )
      })}
    </div>
  )
}

function ThreeImageGrid({ pages, gridSize }) {
  const gridClass = classnames({
    "community-three-grid": !gridSize || gridSize === 3 || gridSize === 6,
    "community-4-grid": gridSize === 4,
    "community-5-grid": gridSize === 5
  })
  return (
    <div className="columns is-desktop community-row">
      <div className="column is-2" />
      <div className={`column community-item ${gridClass}`}>
        {pages &&
          pages.map((page, i) => {
            if (page.page.href) {
              return (
                <div key={i}>
                  <Link
                    className="hover-link"
                    to={page.page.href}
                    style={{ display: "block" }}>
                    <NuvoImage
                      useAR
                      cloudName="nuvolum"
                      publicId={page.page.img}
                      width="auto"
                      responsive
                      className="meet-our-doctor"
                    />
                  </Link>
                  <div className="community-subtext">
                    <Link
                      className="heading-link hover-link"
                      to={page.page.href}>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: page.page.heading
                        }}
                      />
                    </Link>
                    <Link
                      className="standard-button contained"
                      style={{ marginTop: 10 }}
                      to={page.page.href}>
                      Learn More
                    </Link>
                  </div>
                </div>
              )
            }
            return (
              <div key={i}>
                <NuvoImage
                  useAR
                  cloudName="nuvolum"
                  publicId={page.page.img}
                  width="auto"
                  responsive
                  className="meet-our-doctor"
                />
                <div className="community-subtext">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: page.page.heading
                    }}
                  />
                </div>
              </div>
            )
          })}
      </div>
      <div className="column is-2" />
    </div>
  )
}

export const pageQuery = graphql`
  query CommunityQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        blurb
        getStarted {
          heading
          blurb
          buttons {
            button {
              appearance
              buttonText
              href
            }
          }
        }
        pages {
          page {
            heading
            img
            href
          }
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
      }
    }
  }
`

export default CommunityQueryPage
