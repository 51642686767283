import React from "react"

import "./LocationInfo.scss"

import MarkdownViewer from "@components/MarkdownViewer"
import GeneralHours from "../components/GeneralHours"
import ImageMeta from "./ImageMeta"

import ButtonGroupMap from "./Button/ButtonGroupMap"

const LocationInfo = ({ data, location, relatedLocations, napRef }) => {
  const combinedLocations = [location].concat(relatedLocations)

  return (
    <div className="location-info row color-back" id="nap">
      <div className="columns">
        <div className="column is-2" />
        <div className="column">
          <h3 className="has-text-centered-desktop" id="nap" ref={napRef}>
            {data.heading}
          </h3>
          <MarkdownViewer markdown={data.text} />
          <div className="nap-list">
            {data.listOne && <MarkdownViewer markdown={data.listOne} />}
            {data.listTwo && <MarkdownViewer markdown={data.listTwo} />}
          </div>
        </div>
        <div className="column is-2" />
      </div>

      <div className="columns">
        <div className="column is-2" />
        <div className="column">
          <div className="map-address-grid">
            <div className="info-address">
              {combinedLocations.map(info => (
                <div className="location-block" key={info.locationName}>
                  <h4>Contact Us in {info.locationName}</h4>
                  {!data.showAddress ? (
                    ""
                  ) : (
                    <div>
                      <a
                        href={info.googlePlaceLink}
                        target="_blank"
                        title={`Get directions to our ${info.locationName} location.`}
                        rel="noreferrer">
                        {`${info.streetNumber} ${info.streetName}`}
                        <div className="address-spacer" />
                        {`${info.cityName}, ${info.stateAbbr} ${info.cityZip}`}
                      </a>
                    </div>
                  )}

                  {!data.showPhone ? (
                    ""
                  ) : (
                    <div className="mt-1">
                      <h6>Phone</h6>
                      <a href={`tel:+1${info.phone}`} title="Give us a call">
                        {info.phone}
                      </a>
                    </div>
                  )}

                  {!data.showEmail ? (
                    ""
                  ) : (
                    <div className="mt-1">
                      <h6>Email</h6>
                      <a href={`mailto:${info.email}`} title="Email us">
                        {info.email}
                      </a>
                    </div>
                  )}

                  <GeneralHours
                    className="mt-1 hours"
                    language="en"
                    contact
                    locationHours={location.locationHours}
                  />

                  {location.calendar && (
                    <a
                      style={{ marginTop: "20px", display: "block" }}
                      href={location.calendar}
                      title="Open Calendar"
                      aria-label="Open link in new browser tab"
                      target="_blank">
                      {location.locationName} Days & Hours{" "}
                      <span style={{ fontWeight: "bold" }}>Click Here</span>
                    </a>
                  )}
                </div>
              ))}
              <div className="other-location-buttons">
                <h4>Other Locations</h4>
                <div>
                  <ButtonGroupMap buttons={data.buttons} />
                </div>
              </div>
            </div>

            <div
              className="info-map"
              dangerouslySetInnerHTML={{ __html: data.mapIframe }}
            />
            {/* <MarkdownViewer markdown={data.mapIframe} /> */}
          </div>
        </div>
        <div className="column is-2" />
      </div>
    </div>
  )
}

export default LocationInfo
