import PropTypes from "prop-types"
import React from "react"
import Button from "@components/Button/Button"
import CTA from "./CTA"

const SideCTA = ({
  buttonCentered,
  buttonText,
  buttonUrl,
  className,
  download,
  heading,
  paragraph,
  sideColumnIs,
  subheading
}) => (
  <CTA
    className={className || ""}
    sideColumnIs={sideColumnIs}
    headingColumns={
      <div className="column">
        <h6 className="cta-subheading mt-0 mb-0">{subheading}</h6>
        <h5 className="cta-heading mt-0">{heading}</h5>
      </div>
    }
    textColumns={
      <div className="column">
        <p>{paragraph}</p>
      </div>
    }
    buttonColumns={
      <div className="column">
        <Button
          contained
          href={buttonUrl}
          buttonText={buttonText}
          download={download || false}
        />
      </div>
    }
    buttonCentered={buttonCentered}
  />
)

SideCTA.propTypes = {
  buttonCentered: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  className: PropTypes.string,
  download: PropTypes.bool,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  sideColumnIs: PropTypes.number,
  subheading: PropTypes.string
}

export default SideCTA
