import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import SEO from "../components/seo"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

const ThankYouPage = props => {
  const post = props.data.allUniquePagesJson.nodes[0]
  const { language } = props.pageContext

  return (
    <Layout language="en">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={props.location.pathname}
        lang={language}
      />
      <div className="joshua-tree-content">
        <div className="columns">
          <div className="column">
            <div className="columns top-section text-section is-mobile color-back">
              <div className="column is-4" />
              <div className="column is-16 thank-you-body">
                <div
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.body)
                  )}
                />
                <ButtonGroupMap buttons={post.buttons} />
              </div>
              <div className="column is-4" />
            </div>
            <div className="column is-4" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query thankYouPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        body
        metaTitle
        metaDescription
        buttons {
          button {
            buttonText
            href
            destination
            appearance
          }
        }
      }
    }
  }
`

export default ThankYouPage
