import React from "react"

import MarkdownViewer from "@components/MarkdownViewer"
import Button from "@components/Button/Button"
import CenterColumn from "../../components/Bulma/CenterColumn"
import ImageMeta from "../../components/ImageMeta"

const RewardsBanner = ({ post }) => {
  if (!post.hasThisSection) return <></>

  return (
    <div className="home-rewards">
      <CenterColumn
        leftColWidth={4}
        rightColWidth={4}
        centerColumn={
          <div className="column" data-aos="fade-up" data-aos-duration="1200">
            <ImageMeta cloudName="nuvolum" publicId={post.imageId} responsive />
            <div
              className="offset-header"
              data-aos="fade-left"
              data-aos-duration="1400">
              <h2>{post.header}</h2>
            </div>
            <MarkdownViewer markdown={post.blurb} />
            <Button
              href={post.button.href}
              buttonText={post.button.buttonText}
            />
          </div>
        }
      />
    </div>
  )
}

export default RewardsBanner
