import React from "react"
import classNames from "classnames"
import ImageMeta from "../../components/ImageMeta"

import TheaterVideo from "@components/Theater/TheaterVideo"

function OurReviews(props) {
  if (!props.post.hearWhatTheyHaveToSay.hasThisSection) return <></>

  // Use Image strip style
  if (props.post.hearWhatTheyHaveToSay.useImageStrip) {
    return (
      <ImageStripStyle
        language={props.language}
        data={props.post.hearWhatTheyHaveToSay}
      />
    )
  }

  function hasBoth() {
    if (
      props.post.hearWhatTheyHaveToSay.videos.patients.youtube &&
      props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube
    ) {
      return true
    } else {
      return false
    }
  }

  function hasOnlyOne() {
    if (!hasBoth()) {
      if (
        props.post.hearWhatTheyHaveToSay.videos.patients.youtube ||
        props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube
      ) {
        return true
      }
    } else {
      return false
    }
  }

  let columnsClasses = classNames("columns", {
    "has-text-centered": hasOnlyOne()
  })
  let blockClasses = classNames(" patient-block", {
    "is-centered": hasOnlyOne()
  })
  let columnWidth = classNames("column", {
    "is-9": hasOnlyOne()
  })

  if (
    props.post.hearWhatTheyHaveToSay.videos.patients.youtube ||
    props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube
  ) {
    return (
      <div
        className={`body-section reviews-block ${
          props.layout ? "location-section" : ""
        }`}>
        <div className={columnsClasses}>
          <div className="column is-4"></div>
          <div className="column" style={{ marginBottom: "2.25rem" }}>
            <h3 className="has-text-centered-tablet">
              {props.post.hearWhatTheyHaveToSay.heading}
            </h3>
          </div>
          <div className="column is-4"></div>
        </div>
        <div className="columns">
          <div className="column is-4" />

          <div className={`is-centered ${blockClasses}`}>
            {props.post.hearWhatTheyHaveToSay.videos.patients.youtube &&
              props.post.hearWhatTheyHaveToSay.videos.patients.imageId && (
                <div
                  className={` patients-and-referrals`}
                  style={{ marginBottom: 40 }}>
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.patients.youtube}`}
                    hasWatchVideo={false}
                    controls={true}
                    playing={true}
                    language={props.language}>
                    <ImageMeta
                      cloudName="nuvolum"
                      className="hoverable is-hidden-mobile"
                      responsiveUseBreakpoints
                      responsive
                      publicId={
                        props.post.hearWhatTheyHaveToSay.videos.patients.imageId
                      }
                      version="1580777124"
                      width="auto"
                    />
                    <ImageMeta
                      cloudName="nuvolum"
                      className="hoverable is-hidden-tablet"
                      responsiveUseBreakpoints
                      responsive
                      publicId={
                        props.post.hearWhatTheyHaveToSay.videos.patients
                          .imageIdMobile
                      }
                      version="1580777124"
                      width="auto"
                    />
                  </TheaterVideo>
                  <div className="review-block-caption">
                    <h5 className="image-caption">
                      <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.patients.youtube}`}
                        hasWatchVideo={false}
                        controls={true}
                        playing={true}
                        language={props.language}>
                        <a>
                          {
                            props.post.hearWhatTheyHaveToSay.videos.patients
                              .caption
                          }
                        </a>
                      </TheaterVideo>
                    </h5>
                    <TheaterVideo
                      videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.patients.youtube}`}
                      buttonText={
                        props.language === "es" ? "Ver Video" : "Watch Video"
                      }
                      textOnly
                      controls={true}
                      playing={true}
                      onlyButton={true}
                      language={props.language}
                      containerClass="review-block-theater"
                    />
                  </div>
                </div>
              )}
            {props.post.hearWhatTheyHaveToSay.videos.referringProviders
              .youtube &&
              props.post.hearWhatTheyHaveToSay.videos.referringProviders
                .imageId && (
                <div className={` patients-and-referrals`}>
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube}`}
                    hasWatchVideo={false}
                    controls={true}
                    playing={true}
                    language={props.language}>
                    <ImageMeta
                      cloudName="nuvolum"
                      className="hoverable is-hidden-mobile"
                      publicId={
                        props.post.hearWhatTheyHaveToSay.videos
                          .referringProviders.imageId
                      }
                      width="auto"
                    />
                    <ImageMeta
                      cloudName="nuvolum"
                      className="hoverable is-hidden-tablet"
                      publicId={
                        props.post.hearWhatTheyHaveToSay.videos
                          .referringProviders.imageIdMobile
                      }
                      width="auto"
                    />
                  </TheaterVideo>
                  <div className="review-block-caption">
                    <h5 className="image-caption">
                      <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube}`}
                        hasWatchVideo={false}
                        controls={true}
                        playing={true}
                        language={props.language}>
                        <a>
                          {
                            props.post.hearWhatTheyHaveToSay.videos
                              .referringProviders.caption
                          }
                        </a>
                      </TheaterVideo>
                    </h5>
                    <TheaterVideo
                      videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube}`}
                      buttonText={
                        props.language === "es" ? "Ver Video" : "Watch Video"
                      }
                      textOnly
                      controls={true}
                      playing={true}
                      onlyButton={true}
                      language={props.language}
                      containerClass="review-block-theater"
                    />
                  </div>
                </div>
              )}
          </div>
          <div className="column is-4" />
        </div>
      </div>
    )
  } else {
    return ""
  }
}

const ImageStripStyle = ({ data, language }) => {
  return (
    <div className="columns has-text-centered">
      <div className="column is-3"></div>
      <div className="column">
        <h3 style={{ marginBottom: "40px" }}>{data.heading}</h3>

        <div className="testimonials-img-strip">
          {data.imageStrip.images.map((img, i) => (
            <React.Fragment key={img.imageId}>
              <ImageMeta
                cloudName="nuvolum"
                responsiveUseBreakpoints
                responsive
                publicId={img.imageId}
                width="auto"
              />
            </React.Fragment>
          ))}
        </div>

        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${data.imageStrip.youtube}`}
          playing
          onlyButton
          language={language}
          buttonClass="contained"
        />
      </div>
      <div className="column is-3"></div>
    </div>
  )
}

export default OurReviews
