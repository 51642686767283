import React from "react"
import ImageMeta from "../components/ImageMeta"
import ImageSlider from "../components/ImageSlider/ImageSlider"
import Layout from "../components/layout"
import SEO from "../components/seo"

const EmpowerProgram = () => {
  return (
    <Layout language="en">
      <SEO title="EMPOWER" description="EMPOWER" />
      <div className="emp">
        <div className="emp__hero">
          <div className="emp__section emp__container">
            <div className="emp__hero-grid">
              <img
                src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/empower-logo"
                alt="empower logo"
                className="emp__logo"
              />
              <h1>
                EMPOWER <br></br>
                Others to<br></br>
                Be Kind
              </h1>

              <div className="emp__hero-slider">
                <img
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/empower-megaphone"
                  alt="empower logo"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="emp__section emp__container">
          <div className="emp__body" style={{ display: "block" }}>
            <div>
              <p>
                When you’re kind to others, you do good and you feel good. At
                Nalchajian Orthodontics, we continue to promote kindness with
                our annual <b>EMPOWER Human Kindness</b> initiative.
              </p>
              <p>
                This year, we invited students from across our region to tell us
                how they EMPOWER Human Kindness at school and in our community
                through tutoring, volunteering, community cleanup events, and
                more. After reviewing all submissions, we selected Carson, 15, a
                freshman at Endeavor Charter School in Fresno.
              </p>
              <p>
                In his video application, Carson explained that he has been part
                of the Sanger Fire Explorer Program for just over a year. This
                program has given him similar training to that of an entry-level
                firefighter. It has given him the opportunity to assist with
                over 30 medical calls throughout the community, allowing him to
                go into homes, help those in car crashes, and more. He explained
                that when people call 911, they expect help, and he is honored
                that he can be one of the people who provides assistance to
                those in need. Beyond the fire department, Carson volunteers at
                his church and helps teachers at school in small and large ways,
                knowing that they do not always get the support they need.
                Carson hopes to continue to serve throughout our region and
                community as he continues on through high school and beyond,
                knowing that he wants to help wherever and whenever he can.
              </p>
              <p>
                It is our pleasure to continue to partner with Carson as he
                works to EMPOWER Human Kindness in our community and beyond. As
                this year’s EMPOWER Human Kindness Ambassador, he also received
                a $2,500 scholarship!
              </p>
              <p>
                We hope that this initiative and Carson’s story helps students
                express themselves and empowers them to continue to spread
                kindness in the days, months, and years ahead.
              </p>
              <p>
                <i>
                  Follow us on{" "}
                  <a
                    href="https://www.facebook.com/Nalchajian.Orthodontics"
                    target="_blank"
                    title="Follow Us On Facebook">
                    Faceboook
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.instagram.com/nalchajianorthodontics/"
                    title="Follow Us On Instagram"
                    target="_blank">
                    Instagram
                  </a>{" "}
                  as we continue to <b>EMPOWER Human Kindness</b> in our
                  community.
                </i>
              </p>
            </div>

            {/* <div className="emp__how">
                <div className="emp__bracket" />
                <div>
                  <div className="emp__how-content">
                    <h2 className="emp__how-heading">
                      Teach it. Show it. <br></br> Get Recognized.
                    </h2>
                    <h3 className="emp__subheading">HERE’S HOW IT WORKS:</h3>
                    <ul>
                      <li>
                        Students of all ages can share their stories from now
                        through November 3, 2022, with a video or written
                        submission.
                      </li>
                      <li>
                        Students, parents, teachers, coaches, and other
                        community members can nominate a student by submitting a
                        story or video.
                      </li>
                      <li>
                        After all the stories have been submitted, we will
                        announce our <b>EMPOWER Human Kindness</b> Ambassador in
                        November in celebration of World Kindness Day.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="emp__bracket bottom" />
              </div> */}
          </div>
        </div>

        <div className="emp__section emp__container image-slider">
          {/* <ImageSlider
              images={[
                // "NALO/DEV/emp-22-01",
                // "NALO/DEV/emp-22-02",
                // "NALO/DEV/emp-22-03",
                // "NALO/DEV/emp-22-04",
                "NALO/DEV/emp-22-05",
                // "NALO/DEV/emp-22-06",
              ]}
            /> */}
          <ImageMeta
            cloudName="nuvolum"
            publicId="NALO/DEV/emp-22-05"
            responsive
            width="auto"
          />
        </div>

        {/* <div className="emp__form">
            <div className="emp__form-banner">
              <p className="emp__container">Submit your application</p>
            </div>
            <div className="emp__seamless emp__section">
              <iframe
                class="seamlessdocsEmbededIframe"
                src="https://secureform.seamlessdocs.com/f/c4d66d8698da423de96450b8c717f1b7?embedded=true"
                width="100%"
                height="1200px"
                frameborder="0"
                allowtransparency="true"
                sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>
            </div>
          </div> */}
      </div>
    </Layout>
  )
}

export default EmpowerProgram
