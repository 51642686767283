import React, { useState, useEffect } from "react"
import { Dialog } from "@blueprintjs/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft"
import { faChevronSquareUp } from "@fortawesome/pro-duotone-svg-icons/faChevronSquareUp"
import MarkdownViewer from "@components/MarkdownViewer"

import useWindowDimensions from "../../hooks/useWindowDimensions"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import CloseIcon from "../../components/CloseIcon"

const Services = ({ post }) => {
  if (!post.hasThisSection) return ""

  const { width } = useWindowDimensions()

  const [activeItem, setActiveItem] = useState(post.items[0])
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (isModalOpen) {
      document.querySelector("html").style.overflowY = "hidden"
    } else {
      document.querySelector("html").style.removeProperty("overflow-y")
    }

    return () => {
      document.querySelector("html").style.removeProperty("overflow-y")
    }
  }, [isModalOpen])

  const handleServiceChange = service => {
    setActiveItem(service)
    const el = document.getElementsByClassName("services-menu")[0]
    window.scroll({ top: el.offsetTop - 100, left: 0, behavior: "smooth" })
  }

  const handleModal = service => {
    if (width <= 1024) {
      setActiveItem(service)
      setIsModalOpen(true)
    }
  }

  const scrollUp = () => {
    const el = document.querySelector("#modal-top")
    // window.scroll({ top: (el.offsetTop - 100), left: 0, behavior: 'smooth' });
    el.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <>
      <div className="home-services mb-0-mobile">
        <div className="columns">
          <div className="column is-3" />
          <div className="column" data-aos-duration="1200" data-aos="fade-left">
            <div className="has-text-centered-tablet">
              <h2 className="h3-style">{post.heading}</h2>
              <MarkdownViewer markdown={post.blurb} />
            </div>

            <div className="services-menu">
              <div className="services-menu-list">
                {post.items.map((item, i) => (
                  <div
                    className="service-item"
                    key={i}
                    onClick={() => handleModal(item)}>
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={item.imageId}
                      responsive
                      width="auto"
                    />
                    <MarkdownViewer
                      className="service-item-heading"
                      markdown={item.heading}
                    />
                    <div
                      className={`service-icon ${
                        item.heading === activeItem.heading ? "icon-active" : ""
                      }`}
                      onClick={() => handleServiceChange(item)}>
                      <FontAwesomeIcon
                        className="button-icon"
                        icon={faChevronLeft}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="services-menu-content">
                {post.items.map((item, i) => (
                  <div
                    key={i}
                    className={
                      item.heading === activeItem.heading
                        ? "active-content"
                        : "hidden-content"
                    }>
                    {/* <p className="service-item-heading">{item.heading}</p> */}
                    <MarkdownViewer markdown={item.blurb} />
                  </div>
                ))}
              </div>
            </div>

            <ButtonGroupMap isCentered buttons={post.buttons} />
          </div>
          <div className="column is-3" />
        </div>
      </div>

      <Dialog
        id="modal-top"
        canOutsideClickClose
        enforceFocus
        autoFocus
        canEscapeKeyClose
        isOpen={isModalOpen}
        usePortal
        onClose={() => setIsModalOpen(false)}
        className="anesthesia-dialog modal-top">
        <div className="dialog-content services-modal">
          <div
            id="modal-top"
            style={{ position: "absolute", top: "-100px", left: "0" }}
          />

          <CloseIcon onClick={() => setIsModalOpen(false)} />
          {/* <p className="service-item-heading">{activeItem.heading}</p> */}
          <MarkdownViewer markdown={activeItem.blurb} />

          <div className="scroll-up" onClick={scrollUp}>
            <FontAwesomeIcon className="button-icon" icon={faChevronSquareUp} />
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Services
