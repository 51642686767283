import React from "react"
import { graphql } from "gatsby"
import MarkdownViewer from "@components/MarkdownViewer"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"

import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import TextPhoto from "../components/TextPhoto/TextPhoto"
import GetStarted from "../components/CTA/GetStarted"
import SEO from "../components/seo"
import TheaterVideo from "@components/Theater/TheaterVideo"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

const YourFirstVisitPage = ({ data, location, pageContext }) => {
  const post = data.allUniquePagesJson.nodes[0]

  const { language } = pageContext

  return (
    <Layout pageTitle="first-visit">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <section
        className="joshua-tree-content
            your-first-visit-top
            top-section
            section
            color-back
            text-section
            is-mobile">
        <div className="columns has-text-centered">
          <div className="column">
            <h1>{post.heading}</h1>
          </div>
        </div>

        <div className="columns">
          <div className="column is-3" />
          <div
            className="column"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(post.topBlurb)
            )}
          />
          <div className="column is-3" />
        </div>
        <div className="columns">
          <div className="column is-3" />
          <div className="column has-text-centered">
            <TheaterVideo
              language={language}
              videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
              controls
              playing
              hasWatchVideo
              buttonClass="contained"
            />
          </div>
          <div className="column is-3" />
        </div>
      </section>

      <div className="joshua-tree-content your-first-visit">
        {!post.hasImages ? (
          <TextOnly post={post} />
        ) : (
          <>
            <section className="body-section">
              <TextPhoto
                columnsClasses="is-vcentered reverse-columns"
                topDown={false}
                sideColumnIs={3}
                centerColumnIs={1}
                leftColumn={
                  <div className="column">
                    <h2 className="">{post.prepareFirstAppointment.heading}</h2>
                    <div
                      dangerouslySetInnerHTML={createHtml(
                        converter.makeHtml(post.prepareFirstAppointment.text)
                      )}
                    />
                    {post.prepareFirstAppointment.buttons && (
                      <ButtonGroupMap
                        tight
                        noReverse
                        buttons={post.prepareFirstAppointment.buttons}
                      />
                    )}
                  </div>
                }
                rightColumn={
                  <div className="column">
                    <NuvoImage
                      cloudName="nuvolum"
                      className="is-fullwidth-mobile"
                      publicId={post.prepareFirstAppointment.imageId}
                      responsive
                      useAR
                      width="auto"
                    />
                  </div>
                }
              />
            </section>
            <section className="body-section">
              <TextPhoto
                columnsClasses="is-vcentered"
                sideColumnIs={2}
                centerColumnIs={1}
                leftColumn={
                  <div className="column is-10">
                    <NuvoImage
                      cloudName="nuvolum"
                      className="is-fullwidth-mobile"
                      publicId={post.itemsToBring.imageId}
                      useAR
                      width="auto"
                    />
                  </div>
                }
                rightColumn={
                  <div className="column is-9 right-column-under">
                    <h3 className="items-to-bring">
                      {post.itemsToBring.heading}
                    </h3>
                    <div
                      dangerouslySetInnerHTML={createHtml(
                        converter.makeHtml(post.itemsToBring.text)
                      )}
                    />
                  </div>
                }
              />
            </section>

            <section
              className="body-section what-to-expect"
              style={{ paddingTop: "0" }}>
              <div className="columns">
                <div className="column is-3" />
                <div className="column">
                  <h3>{post.whatToExpect.heading}</h3>
                  <MarkdownViewer markdown={post.whatToExpect.text} />
                </div>
                <div className="column is-3" />
              </div>
            </section>
          </>
        )}

        {/*
          <div className="columns">
            <div className="column is-offset-2 is-20">
              <div className="hl"></div>
            </div>
          </div> */}

        {post.scanning && (
          <section className="body-section what-to-expect color-back ">
            <div className="columns three-d-scanning">
              <div className="column is-3" />
              <div className="column">
                <h3>{post.scanning.heading}</h3>
                <div
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.scanning.text)
                  )}
                />
                {post.scanning.buttons && (
                  <ButtonGroupMap
                    tight
                    noReverse
                    buttons={post.scanning.buttons}
                  />
                )}
              </div>
              <div className="column is-3" />
            </div>
          </section>
        )}

        <section className="body-section financial-section">
          <div className="columns">
            <div className="column is-3" />
            <div className="column">
              <h3 style={{ marginBottom: "1.5rem", marginTop: "0" }}>
                {post.financialInsurancePolicy.heading}
              </h3>
            </div>
          </div>
          <div className="columns">
            <div className="column is-3" />
            <div className="column">
              <MarkdownViewer markdown={post.financialInsurancePolicy.text} />

              {post.financialInsurancePolicy.paymentTypes.hasThisSection && (
                <div className="columns">
                  <div className="column">
                    <MarkdownViewer
                      markdown={
                        post.financialInsurancePolicy.paymentTypes.paymentType1
                          .blurb
                      }
                    />
                    <ButtonGroupMap
                      buttons={
                        post.financialInsurancePolicy.paymentTypes.paymentType1
                          .buttons
                      }
                      isCentered={false}
                    />
                  </div>
                  <div className="column is-2" />
                  <div className="column vl is-2 is-hidden-mobile" />
                  <div className="column">
                    <MarkdownViewer
                      markdown={
                        post.financialInsurancePolicy.paymentTypes.paymentType2
                          .blurb
                      }
                    />
                    <ButtonGroupMap
                      buttons={
                        post.financialInsurancePolicy.paymentTypes.paymentType2
                          .buttons
                      }
                      isCentered={false}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="column is-3" />
          </div>
        </section>

        <section className="body-section color-back last">
          <GetStarted
            headingSideColumnIs={4}
            sideColumnIs={4}
            centerHeading
            centerText
            heading={
              language === "es"
                ? post.getStartedDataEs.heading
                : post.getStarted.heading
            }
            paragraph={
              language === "es"
                ? post.getStartedDataEs.blurb
                : post.getStarted.blurb
            }
            buttonText={
              language === "es"
                ? post.getStartedDataEs.buttonText
                : post.getStarted.buttonText
            }
            buttonUrl={
              language === "es"
                ? post.getStartedDataEs.href
                : post.getStarted.href
            }
            buttons={post.getStarted.buttons}
          />
        </section>
      </div>
    </Layout>
  )
}

function TextOnly({ post }) {
  return (
    <div className="text-only">
      <section className="body-section">
        <div className="columns">
          <div className="column is-3" />
          <div className="column">
            <h2 className="h3-style">{post.prepareFirstAppointment.heading}</h2>
            <div
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(post.prepareFirstAppointment.text)
              )}
            />
            {post.prepareFirstAppointment.buttons && (
              <ButtonGroupMap
                tight
                noReverse
                buttons={post.prepareFirstAppointment.buttons}
              />
            )}
          </div>
          <div className="column is-3" />
        </div>
      </section>

      <section className="body-section items-to-bring color-back">
        <div className="columns">
          <div className="column is-3" />
          <div className="column">
            <h3 className="items-to-bring">{post.itemsToBring.heading}</h3>
            <div
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(post.itemsToBring.text)
              )}
            />
          </div>
          <div className="column is-3" />
        </div>
      </section>

      <section className="body-section what-to-expect">
        <div className="columns">
          <div className="column is-3" />
          <div className="column">
            <h3>{post.whatToExpect.heading}</h3>
            <div
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(post.whatToExpect.text)
              )}
            />
            {post.whatToExpect.buttons && (
              <ButtonGroupMap
                tight
                noReverse
                buttons={post.whatToExpect.buttons}
              />
            )}
          </div>
          <div className="column is-3" />
        </div>
      </section>

      <section className="body-section what-to-expect color-back ">
        <div className="columns three-d-scanning">
          <div className="column is-3" />
          <div className="column">
            <h3>{post.scanning.heading}</h3>
            <div
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(post.scanning.text)
              )}
            />
            {post.scanning.buttons && (
              <ButtonGroupMap tight noReverse buttons={post.scanning.buttons} />
            )}
          </div>
          <div className="column is-3" />
        </div>
      </section>
    </div>
  )
}

export const pageQuery = graphql`
  query yourFirstVisitPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        topBlurb
        youtube
        metaTitle
        metaDescription
        hasImages
        title
        financialInsurancePolicy {
          heading
          text
          paymentTypes {
            hasThisSection
            paymentType1 {
              blurb
              buttons {
                button {
                  buttonText
                  href
                  destination
                  appearance
                }
              }
            }
            paymentType2 {
              blurb
              buttons {
                button {
                  buttonText
                  href
                  destination
                  appearance
                }
              }
            }
          }
        }
        prepareFirstAppointment {
          imageId
          text
          heading
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
        itemsToBring {
          heading
          imageId
          text
        }
        whatToExpect {
          heading
          text
        }
        scanning {
          heading
          text
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
        }
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
      }
    }
  }
`
export default YourFirstVisitPage
