import PropTypes from "prop-types"
import React from "react"
import MarkdownViewer from "@components/MarkdownViewer"

class FeaturedList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var featuredList = this.props.listContent

    return (
      <div className="body-section featured-list color-back">
        <div className="columns" style={{ paddingBottom: "32px" }}>
          <div className={`column is-${this.props.headingSideColumnIs}`}></div>
          <div className="column has-text-centered mobile-left">
            <MarkdownViewer markdown={featuredList.featuredListHeading} />
          </div>
          <div className={`column is-${this.props.headingSideColumnIs}`}></div>
        </div>
        <div className="columns">
          <div className={`column is-${this.props.listSideColumnIs}`}></div>
          <div className="column">
            {featuredList.featuredListItems.map((item, i) => {
              return (
                <div
                  key={item.featuredListItem.featuredListItemHeading}
                  className="reasons-why-grid-row-single">
                  <div></div>
                  <div className="reason-number-wrapper">
                    <span className="reason-number-circle">{i + 1}</span>
                  </div>
                  <div>
                    <h3>{item.featuredListItem.featuredListItemHeading}</h3>
                    <MarkdownViewer
                      className="large-p"
                      markdown={item.featuredListItem.featuredListItemBlurb}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className={`column is-${this.props.listSideColumnIs}`}></div>
        </div>
      </div>
    )
  }
}

FeaturedList.propTypes = {
  listContent: PropTypes.object,
  textSection: PropTypes.string,
  headingSideColumnIs: PropTypes.number,
  listSideColumnIs: PropTypes.number,
  featuredListHeading: PropTypes.string
}

export default FeaturedList
