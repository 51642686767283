import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Button from "@components/Button/Button"
import MarkdownViewer from "@components/MarkdownViewer"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"

const LearnMoreCards = ({ post }) => {
  if (!post.hasThisSection) return ""

  return (
    <div className="info-cards color-back">
      <div className="columns info-heading">
        <div className="column is-5"></div>
        <div
          className="column has-text-centered"
          data-aos-duration="1200"
          data-aos="fade-left">
          <h3 className="has-text-centered">{post.heading}</h3>
          <MarkdownViewer markdown={post.blurb} />
        </div>
        <div className="column is-5"></div>
      </div>

      <div className="columns">
        {/* <div className="column is-2"></div> */}
        <div className="column card-section">
          {post.cards &&
            post.cards.map((card, i) => (
              <div
                className="card-wrapper"
                key={i}
                data-aos-duration="1200"
                data-aos="fade-up"
                data-aos-delay={`${200 * i}`}>
                <div className="info-card">
                  <ImageMeta
                    publicId={card.imageId}
                    cloudName="nuvolum"
                    width="auto"
                    responsive
                  />

                  <div className="card-content">
                    <h4 className="card-heading">
                      <MarkdownViewer markdown={card.heading} />
                    </h4>
                    <MarkdownViewer className="card-p" markdown={card.blurb} />
                  </div>
                  {card.button.href && card.button.buttonText && (
                    <Button
                      className="info-button"
                      contained
                      internal
                      href={card.button.href}
                      buttonText={card.button.buttonText}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
        {/* <div className="column is-2"></div> */}
      </div>
      <ButtonGroupMap buttons={post.buttons} isCentered />
    </div>
  )
}

export default LearnMoreCards
