import React, { useState, useEffect } from "react"
import GeneralHours from "../components/GeneralHours"
import { graphql } from "gatsby"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import SEO from "../components/seo"
import LocationBlocks from "./contact/LocationBlocks"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"

import { Ratings } from "./index/RatingsAndReviews"
import { AllSocialReviews, SocialIconLink } from "../components/SocialReviews"
import SocialIcon from "../components/SocialReviews/SocialIcon"
import ContactUs from "../components/ContactUs"

import {
  getPostObj,
  splitCamelCaseToString,
  parseSearchQuery,
  telephoneStrip
} from "../utils/utils"
import LocationForms from "./contact/LocationForms"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

const ContactUsPage = ({ pageContext, location, data }) => {
  const postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  const { post, language } = postObj

  const { locationHours } = data.allLocationsJson.nodes[0].locationData

  const locations = data.allLocationsJson.nodes

  let socials = locations[0].locationData.socialAccounts

  const allowedSocials = ["Facebook", "Instagram"]
  socials = socials.filter(s => allowedSocials.includes(s.platform))

  const [activeForm, setActiveForm] = useState({ id: "", name: "" })
  const [pageState, setPageState] = useState("")

  useEffect(() => {
    if (typeof window === "undefined") return

    if (location.state?.formId) {
      setActiveForm({
        ...activeForm,
        id: location.state.formId,
        name: location.state.locationName
      })
    } else {
      setActiveForm({ id: "", name: "" })
    }
  }, [location.state?.formId])

  useEffect(() => {
    if (typeof window === "undefined") return

    if (pageState !== location.state?.key) {
      setPageState(location.state?.key)
    }
  }, [location.state?.key])

  const handleFormChange = (formId, locationName) => {
    if (formId === activeForm.id) {
      const form = document.getElementById("contact-page-form")
      setTimeout(() => {
        window.scroll({
          top: form.offsetTop - 100,
          left: 0,
          behavior: "smooth"
        })
      }, 600)
    } else {
      setActiveForm(prevState => ({
        id: formId,
        name: locationName
      }))
    }
  }

  return (
    <Layout
      className={`contact-us ${language === "es" ? "es" : null}`}
      layoutClass="contact-fab"
      language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <span className="menu-spacer" />

      <div className="contact-top-wrapper">
        <div className="">
          <NuvoImage
            className="splash"
            cloudName="nuvolum"
            publicId={post.googleMapsBackground.splashPublicId}
            useAR
            width="auto"
          />
        </div>

        <div className="contact-top-headings">
          <h1>{post.heading}</h1>
        </div>
      </div>

      <div className="body-section contact-us-body">
        <div className="columns">
          <div className="column is-4" />
          <div
            className="column is-10"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}>
            <div className="contact-info is-hidden-tablet">
              {locations.length === 1 && (
                <>
                  <div className="contact-sidebar-block">
                    <h6>{language === "es" ? "Dirección" : "Address"}</h6>
                    <a
                      href={`${locations[0].locationData.googlePlaceLink}`}
                      target="_blank"
                      rel="noreferrer">
                      {`${locations[0].locationData.streetNumber} ${locations[0].locationData.streetName}`}
                      <br />
                      {`${locations[0].locationData.cityName}, ${locations[0].locationData.stateAbbr} ${locations[0].locationData.cityZip}`}
                    </a>
                  </div>
                  <div className="contact-sidebar-block">
                    <h6>{language === "es" ? "Teléfono" : "Phone"}</h6>
                    <a
                      href={`tel:+1${telephoneStrip(
                        locations[0].locationData.phone
                      )}`}>
                      {locations[0].locationData.phone}
                    </a>
                  </div>

                  <div className="contact-sidebar-block">
                    <GeneralHours
                      language={language}
                      contact
                      locationHours={locationHours}
                    />
                  </div>

                  <div className="contact-socials">
                    {socials.map(social => (
                      <React.Fragment key={social.link}>
                        <SocialIconLink
                          platform={social.platform}
                          link={social.link}
                        />
                      </React.Fragment>
                    ))}
                  </div>

                  <div className="contact-sidebar-block mt-3">
                    {post.sidebar.hasThisSection && (
                      <div className="sidebar-buttons">
                        <h6>{post.sidebar.heading}</h6>
                        <ButtonGroupMap buttons={post.sidebar.buttons} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(
                  post.contactBody.heading + post.contactBody.blurb
                )
              )}
            />

            <div className="contact-form-section">
              {locations.length === 1 ? (
                <>
                  <div className="inline-block">
                    <h5>Send Us a Message</h5>
                    <span className="required-fields">
                      <span className="required-asterisk">*</span> Required
                      Fields
                    </span>
                  </div>
                  <Seamless src="/contactForm.js" />
                </>
              ) : (
                <div>
                  <div className="locations-grid">
                    <LocationBlocks
                      language={language}
                      locations={locations}
                      setActiveForm={setActiveForm}
                      handleFormChange={handleFormChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="column is-2" />
          <div className="column is-narrow contact-info is-hidden-mobile">
            {locations.length > 1 && (
              <>
                {/* <LocationBlocks language={language} locations={locations} /> */}

                <div className="contact-sidebar-block">
                  <AllSocialReviews language={language} isColumn />

                  <div
                    className="contact-sidebar-block socials"
                    style={{ margin: "0 20px 40px 20px" }}>
                    <h6 className="mt-0">Find us on social</h6>
                    <div style={{ display: "flex", gap: 20 }}>
                      {socials.map(social => (
                        <React.Fragment key={social.platform}>
                          <a
                            href={social.link}
                            title={`Follow us on ${social.platform}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <SocialIcon social={social} />
                          </a>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
            {locations.length === 1 && (
              <>
                <div className="contact-sidebar-block">
                  <h6>{language === "es" ? "Dirección" : "Address"}</h6>
                  <a
                    href={`${locations[0].locationData.googlePlaceLink}`}
                    target="_blank"
                    rel="noreferrer">
                    {`${locations[0].locationData.streetNumber} ${locations[0].locationData.streetName}`}
                    <br />
                    {`${locations[0].locationData.cityName}, ${locations[0].locationData.stateAbbr} ${locations[0].locationData.cityZip}`}
                  </a>
                </div>
                <div className="contact-sidebar-block">
                  <h6>{language === "es" ? "Teléfono" : "Phone"}</h6>
                  <a
                    href={`tel:+1${telephoneStrip(
                      locations[0].locationData.phone
                    )}`}>
                    {locations[0].locationData.phone}
                  </a>
                </div>

                <div className="contact-sidebar-block">
                  <GeneralHours
                    language={language}
                    contact
                    locationHours={locationHours}
                  />
                </div>

                <div className="contact-socials">
                  {socials.map(social => (
                    <React.Fragment key={social.link}>
                      <SocialIconLink
                        platform={social.platform}
                        link={social.link}
                      />
                    </React.Fragment>
                  ))}
                </div>

                <div className="contact-reviews">
                  {post.sidebar.hasThisSection && (
                    <div className="sidebar-buttons">
                      <h6>{post.sidebar.heading}</h6>
                      <ButtonGroupMap buttons={post.sidebar.buttons} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="column is-5 is-hidden-tablet" />
        </div>

        {language === "es" && (
          <div className="columns">
            <div className="column is-4" />
            <div className="column">
              <div className="contact-reviews contact-reviews-es big">
                {/* <SocialReviews inlineStars iconless google facebook googleHeading language={language} /> */}
                {/* <Ratings language={language} google={google} facebook={facebook} eastGoogle={east} coastalGoogle={coastal} /> */}
              </div>
            </div>
            <div className="column is-4" />
          </div>
        )}
      </div>

      <ContactUs colorBack />

      {/* {language !== "es" && (
          <LocationForms
            activeForm={activeForm}
            setActiveForm={setActiveForm}
            pageUpdated={pageState}
          />
        )} */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        contactBody {
          heading
          blurb
        }
        sidebar {
          hasThisSection
          heading
          buttons {
            button {
              href
              buttonText
              appearance
              destination
            }
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }

    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    allLocationsJson(sort: { fields: locationData___order }) {
      nodes {
        locationData {
          ...locationDataFields
        }
        pageData {
          ...locationPageURL
          layout_3 {
            formSection {
              hasForm
              seamlessShortcode
            }
          }
        }
      }
    }
  }
`

export default ContactUsPage
