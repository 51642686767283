import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@components/Button/Button"

const PaymentFailurePage = () => (
  <Layout language="en">
    <SEO
      title="Payment Failure"
      description="Payment Failure"
      robots="noindex"
    />
    <div className="joshua-tree-content">
      <div className="columns top-section text-section white-back is-vcentered">
        <div className="column is-3"></div>

        <div className="column">
          <div className="columns">
            <div className="column has-text-centered-mobile">
              <h1 className="four04-heading">Payment Failure</h1>
              <p className="large">
                Please contact our office if you have any questions or need
                assistance with your online payments.
              </p>
              <div className="columns has-text-centered-mobile">
                <div className="column">
                  <Button contained buttonText="Contact" href="/contact-us/" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-3"></div>
      </div>
    </div>
  </Layout>
)

export default PaymentFailurePage
